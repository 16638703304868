<template>
    <div>
        <rx-navBar title="岗位申请调转"></rx-navBar>
        <div class="part">
            
            <div  class="part-inputpart">
                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-row" @click="openChangeone">
                    <span :class="dataForm.staff_id?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">调转人</span>
                    <span class="content-divide">|</span>
                    <span :class="dataForm.staff_id?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{dataForm.staff_name?dataForm.staff_name:'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class=" alternateStaffListshow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../../assets/images/triangle.png">
                </div>

                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-row" @click="openChangetwo">
                    <span class="content-noneslot"></span>
                    <span class="part-inputpart-row-header">交接人</span>
                    <span class="content-divide">|</span>
                    <span :class="dataForm.handoverStaff_id?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{dataForm.handoverStaff_name?dataForm.handoverStaff_name:'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class="handoverStaffshow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../../assets/images/triangle.png">
                </div>


                <div style="margin-top: 15px;border-radius: 8px" id="expectedDatePanel" class="part-inputpart-row" @click="isExpectedDateShow = true">
                    <span class="part-inputpart-row-enablenon part-inputpart-row-header">调转日期</span>
                    <span class="content-divide">|</span>
                    <span class="part-inputpart-row-graytext" :class="dataForm.operDate ? 'part-inputpart-row-redtext': '' ">
                        {{dataForm.operDate | formatDateTime}}
                    </span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                        src="../../../assets/images/timepick.png">
                </div>

                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-row" @click="isResourceBelogShow = true">
                    <span :class="dataForm.reason_id?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">调转原因</span>
                    <span class="content-divide">|</span>
                    <span :class="dataForm.reason_id?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{dataForm.reason_name?dataForm.reason_name:'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class=" isResourceBelogShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../../assets/images/triangle.png">
                </div>

                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-row">
                    <span :class="dataForm.approvalType.length != 0?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">调转类型</span>
                    <span class="content-divide">|</span>
                    <van-checkbox-group v-model="dataForm.approvalType" direction="horizontal" @change="checkChange">
                        <van-checkbox shape="square" name="1">部门</van-checkbox>
                        <van-checkbox shape="square" name="2">职务</van-checkbox>
                    </van-checkbox-group>
                </div>

                <div style="margin-top: 15px;border-radius: 8px" v-if="dataForm.approvalType.indexOf('1') != -1" class="part-inputpart-row">
                    <div class="content-noneslot"></div>
                    <span class="part-inputpart-row-header">原部门</span>
                    <span class="content-divide">|</span>
                    <input v-model="dataForm.departmentName" disabled placeholder="原部门" >
                </div>

                <div style="margin-top: 15px;border-radius: 8px" v-if="dataForm.approvalType.indexOf('1') != -1" class="part-inputpart-row" @click="chooseDepartment">
                    <div :class="dataForm.newDepartment_id? 'content-have' : 'content-none'"></div>
                    <span class="part-inputpart-row-header">新部门</span>
                    <span class="content-divide">|</span>
                    <input v-model="dataForm.newDepartment_name" placeholder="新部门" >
                </div>

                <div style="margin-top: 15px;border-radius: 8px" v-if="dataForm.approvalType.indexOf('2') != -1" class="part-inputpart-row">
                    <div class="content-noneslot"></div>
                    <span class="part-inputpart-row-header">原职务</span>
                    <span class="content-divide">|</span>
                    <input v-model="dataForm.dutyName" disabled placeholder="原职务" >
                </div>

                <div style="margin-top: 15px;border-radius: 8px" v-if="dataForm.approvalType.indexOf('2') != -1" class="part-inputpart-row" @click="newDutyShow = true">
                    <span :class="dataForm.newDuty_id?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">新职务</span>
                    <span class="content-divide">|</span>
                    <span :class="dataForm.newDuty_id?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{dataForm.newDuty_name?dataForm.newDuty_name:'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class=" newDutyShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../../assets/images/triangle.png">
                </div>

                <div style="margin-top: 15px;border-radius: 8px" v-if="dataForm.approvalType.indexOf('2') != -1" class="part-inputpart-row">
                    <div class="content-noneslot"></div>
                    <span class="part-inputpart-row-header">原级别</span>
                    <span class="content-divide">|</span>
                    <input v-model="dataForm.dutyLevelName" disabled placeholder="原级别" >
                </div>

                 <div style="margin-top: 15px;border-radius: 8px" v-if="dataForm.approvalType.indexOf('2') != -1" class="part-inputpart-row" @click="newDutyLevelShow = true">
                    <span :class="dataForm.newDutyLevel_id?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header">新级别</span>
                    <span class="content-divide">|</span>
                    <span :class="dataForm.newDutyLevel_id?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{dataForm.newDutyLevel_name?dataForm.newDutyLevel_name:'请选择'}}</span>
                    <img class="part-inputpart-row-right"
                        :class=" newDutyLevelShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                        src="../../../assets/images/triangle.png">
                </div>

                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-textarea">
                    <span class="part-inputpart-row-enablenon part-inputpart-row-header">备注</span>
                    <textarea v-model="dataForm.remark" placeholder="备注"
                        :class="true ? 'textarea-readonly' : ''"></textarea>
                </div>

                
                
            </div>
        </div>
        <!-- 调转人 -->

        <van-popup v-model="alternateStaffListshow" position="bottom">
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="searchstaffName"
                size="large"
                @input="initbrowseStaffList()"
                />
            </div>
            <van-picker show-toolbar value-key="staffName" :columns="alternateStaffList"
                        @cancel="alternateStaffListshow = false" @confirm="alternateStaffListEvent" />
        </van-popup>
        <!-- 交接人 -->
        <van-popup v-model="handoverStaffshow" position="bottom">
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="searchstaffNames"
                size="large"
                @input="initbrowseStaffLists()"
                />
            </div>
            <van-picker show-toolbar value-key="staffName" :columns="alternateStaffLists"
                        @cancel="handoverStaffshow = false" @confirm="handoverStaffEvent" />
        </van-popup>
        <!-- 时间下拉 -->
        <van-popup v-model="isExpectedDateShow" position="bottom">
            <van-datetime-picker type="date" title="选择年月日" v-model="currentDate"
                @confirm="expectedDateConfirm" @cancel="isExpectedDateShow = false" />
        </van-popup>
        <!-- <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择年月日"
                item-height="35px"
                @confirm="expectedDateConfirm"
                @cancel="finishTimeCancel"
        /> -->

        <!-- 职务下拉 -->
        <van-popup v-model="newDutyShow" position="bottom">
            <van-picker show-toolbar value-key="dutyName" :columns="dutyList"
                        @cancel="newDutyShow = false" @confirm="newDutyEvent" />
        </van-popup>

        <!-- 级别 -->
        
        <van-popup v-model="newDutyLevelShow" position="bottom">
            <van-picker show-toolbar value-key="name" :columns="dutyLevelList"
                        @cancel="newDutyLevelShow = false" @confirm="newDutyLevelEvent" />
        </van-popup>
        <!-- 调转原因 -->
        <van-popup v-model="isResourceBelogShow" position="bottom">
            <van-picker show-toolbar value-key="dictionaryTitle" :columns="resourceBelogArr"
                @cancel="isResourceBelogShow = false" @confirm="selectResourceBelog" />
        </van-popup>
        
        <div class="bottomBody">
            <div :class="{'part':true,'part-button':true,'part-button-enabled':checkCanSave()}"
                  :disabled="checkCanSave()" @click="save">
                保存
            </div>
        </div>
        <div class="contentFill"></div>
    </div>
</template>

<script>
    import {
        NavBar,
        DatetimePicker,
        Toast,
        RadioGroup,
        Radio,
        Picker,
        Popup,
        Checkbox, CheckboxGroup,Search
    } from 'vant'
    import {
        queryBaseData,
        addOrUpdatePotentialUser,
        queryBusinessCircle,
        queryPotentialUser,
        browseStaffList,
        queyDutySelList,
        employqueyDutyLevel,
        employqueryStaffPosition ,
        addEmployTransferApproval
    } from "../../../getData/getData";
    import {
        getStaffId,
        responseUtil,
        globaluserId
    } from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import eventBus  from "../../../components/rongxun/rx-navBar/envbus.js";
    import util2 from '../../contract/util2'
    export default {

        name: 'addPotentialClient',

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Toast.name]: Toast,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Search.name]: Search,
            rxNavBar,
        },
        created(){

        },
        mounted() {
            // this.initData()
            this.initDicData()
            this.initDutyData()
            this.initbrowseStaffList()
            this.dataForm = this.$options.data().dataForm
            this.disable = false
        },
        data() {
            return {
                currentDate: new Date(),
                dataForm: {
                    staff_id: '',
                    handoverStaff_id: '',
                    approvalType: [],
                    operDate: '',
                    oldDepartment_id: '',
                    oldDuty_id:'',
                    oldDutyLevel_id:'',
                    newDepartment_id: '',
                    newDuty_id: '',
                    newDutyLevel_id: '',
                    reason_id: '',
                    remark: '',
                },
                searchstaffName: '',
                searchstaffNames: '',
                alternateStaffListshow: false,
                handoverStaffshow: false,
                newDutyShow: false,
                newDutyLevelShow:false,
                result: [],
                entryTime: '',
                isExpectedDateShow: false,
                ClickTrigger:true,
                isUserSourceShow: false,
                isResourceBelogShow: false,
                isRentTimeShow: false,
                isAddressHave: false,
                isRequireTypeShow:false,
                isBusinessCircleShow:false,
                isKeyNoteShow : false,
                rentTimeLong: [],
                potentialUserSource: [],
                requireTypeArr: [],
                keyNote: [],
                businessCircleArr:[],
                resourceBelogArr: [],
                title:'添加潜客',
                id:'',
                formData: {
                    userName: '',
                    age: '',
                    mobile: '',
                    gender: '',
                    // 重点需求 ui中没有
                    keyNote_id: '',
                    // 来源
                    potentialUserSource_id: '',

                    // 资源所属
                    resourceBelog: 0,
                    recommendUser_id: '',
                    recommendUserName:'',
                    wechat : '',
                    maintainUserName:'',
                    matainStaff_id:'',
                    isBD:''
                },
                // 需求列表
                requireList: [{
                    roomName:'',
                    lowestPrice: '',
                    highestPrice: '',
                    remarks: '',
                    // 当前租期
                    rentTimeLong: '',
                    businessCircleName:'',
                    // entryTime: new Date(),
                    entryTime : '',
                    // 商圈id
                    businessCircle_id:'',
                    // 房间id
                    roomHouse_id:''
                }],
                // 当前来源
                curIndex:'',
                // 当前类型(私客  公客)
                resourceBelog: {
                    value:0,
                    label:'私客'
                },
                //    当前推荐人
                recommendUser: '',
                addType:'',     //从添加BD进如为2,添加潜客进入为1
                mold:'' ,       //是否从修改进入
                alternateStaffList: [],
                alternateStaffLists: [],
                dutyList: [],
                dutyLevelList: [],

              disable:false,
            }
        },
        filters: {
            formatDateTime(value) {
                if (null == value || '' == value) return '请选择年月日'
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                return y + '-' + MM + '-' + d
            }
        },
        activated() {
            let that = this
            eventBus.$on('chooseDepartment', function(data){
                console.log(data,"data====")
                if(data) {
                    that.dataForm.newDepartment_id = data.selectId
                    that.dataForm.newDepartment_name = data.selectName
                }
            }.bind(this));
        },
        beforeRouteLeave(to, from, next) {
            //清除本地localStorage缓存
            if(to.name == 'jobTransfer'){
                this.dataForm = this.$options.data().dataForm
            }
            next()
        },
        methods: {
            openChangeone() {
                this.alternateStaffListshow = true
                this.searchstaffName = ''
                this.initbrowseStaffList()

            },
            openChangetwo() {
                this.handoverStaffshow = true
                this.searchstaffNames = ''
                this.initbrowseStaffLists()
            },
            chooseDepartment() {
                this.$router.push({
                    name: 'departmentSelect',
                    query : {
                        selectId: this.dataForm.newDepartment_id || '',
                        selectName: this.dataForm.newDepartment_name || '',
                    }
                });
            },
            checkChange(e) {
                console.log(e,"dsadsadsad",this.result)
            },
            initbrowseStaffList() {
                var that = this
                const params = {
                    user_id: getStaffId(),
                    wantDepart: '1',
                    currentPage: '1',
                    numberPage: '10000',
                    searchValue: that.searchstaffName
                }
                browseStaffList(params).then(res => {
                    responseUtil.dealResponse(that, res, () => {
                        that.alternateStaffList = res.data.data.data;
                    })
                })
            },
             initbrowseStaffLists() {
                var that = this
                const params = {
                    user_id: getStaffId(),
                    wantDepart: '1',
                    currentPage: '1',
                    numberPage: '10000',
                    searchValue: that.searchstaffNames
                }
                browseStaffList(params).then(res => {
                    responseUtil.dealResponse(that, res, () => {
                        that.alternateStaffLists = res.data.data.data;
                    })
                })
            },
            // 调转人
            alternateStaffListEvent(value) {
                this.dataForm.staff_id = value.id
                this.dataForm.staff_name = value.staffName
                this.alternateStaffListshow = false;
                this.getqueryStaffPosition()
            },
            getqueryStaffPosition() {
                var that = this

                that.clearSelectionsInfo()

                const params = {
                    id: that.dataForm.staff_id,
                }
                employqueryStaffPosition(params).then(res => {
                    responseUtil.dealResponse(that, res, () => {
                        let paramData = res.data.data;
                        that.dataForm.approvalType = []
                        that.dataForm.departmentName = paramData.departmentName;
                        that.dataForm.oldDepartment_id = paramData.department_id;
                        that.dataForm.dutyLevelName = paramData.dutyLevelName;
                        that.dataForm.oldDutyLevel_id = paramData.dutyLevel_id;
                        that.dataForm.dutyName = paramData.dutyName;
                        that.dataForm.oldDuty_id = paramData.duty_id;
                    })
                })
            },
            clearSelectionsInfo() {
                this.dataForm.newDepartment_name = ''
                this.dataForm.newDepartment_id = ''
                this.dataForm.newDuty_id = ''
                this.dataForm.newDuty_name = ''

                this.dataForm.newDutyLevel_id = ''
                this.dataForm.newDutyLevel_name = ''
            },
            // 交接人
            handoverStaffEvent(value) {
                this.dataForm.handoverStaff_id = value.id
                this.dataForm.handoverStaff_name = value.staffName
                this.handoverStaffshow = false;
            },
            // 职务
            newDutyEvent(value) {
                this.dataForm.newDuty_id = value.id
                this.dataForm.newDuty_name = value.dutyName
                this.newDutyShow =false;
                this.initDutyLevelData()
            },
            // 级别
            newDutyLevelEvent(value) {
                this.dataForm.newDutyLevel_id = value.id
                this.dataForm.newDutyLevel_name = value.name
                this.newDutyLevelShow = false
            },
            initDutyData: function () {
                var that = this;
                let initData = {};
                initData.user_id = getStaffId();
                queyDutySelList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.dutyList = response.data.data.dutyList;
                    })
                });
            },
            initDutyLevelData: function () {
                var that = this;
                let initData = {};
                initData.user_id = getStaffId();
                initData.dutyId = this.dataForm.newDuty_id;
                employqueyDutyLevel(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                    that.dataForm.newDutyLevel_id = "";
                    that.dutyLevelList = response.data.data.data;
                    });
                });
            },
            save(){
                var that = this

                // console.log(that.dataForm.approvalType)   //1部门2职务
                // console.log(that.dataForm.oldDepartment_id)
                // console.log(that.dataForm.newDepartment_id)

                if(that.dataForm.staff_id ==  that.dataForm.handoverStaff_id) {
                    Toast('调转人和交接人相同，请重新选择')
                    return;
                }
                //职务调转
                if(that.dataForm.approvalType.indexOf("2") != -1 && ( that.dataForm.newDuty_id == '' || that.dataForm.newDutyLevel_id == '')) {
                    Toast('请选择需要调转的职务或级别')
                    return;
                }
                if(that.dataForm.approvalType.indexOf("2") != -1 && ( that.dataForm.oldDuty_id == that.dataForm.newDuty_id && that.dataForm.newDutyLevel_id == that.dataForm.oldDutyLevel_id)) {
                    Toast('原职务级别和新职务级别不可相同')
                    return;
                }

                //部门调转
                if(that.dataForm.approvalType.indexOf("1") != -1 && (that.dataForm.newDepartment_id == '') ) {
                    Toast('请选择需要调转的部门')
                    return;
                }
                if(that.dataForm.approvalType.indexOf("1") != -1 && (that.dataForm.oldDepartment_id == that.dataForm.newDepartment_id) ) {
                    Toast('部门相同，请选择不同的部门')
                    return;
                }

                if(this.disable){
                  return;
                }
                const params = {
                    addStaff_id: getStaffId(),
                    staff_id: this.dataForm.staff_id,
                    handoverStaff_id: this.dataForm.handoverStaff_id,
                    approvalType: this.dataForm.approvalType.join(','),
                    operDate:util2.dateToString(this.dataForm.operDate),
                    oldDepartment_id: this.dataForm.oldDepartment_id,
                    oldDuty_id:this.dataForm.oldDuty_id,
                    oldDutyLevel_id:this.dataForm.oldDutyLevel_id,
                    newDepartment_id: that.dataForm.approvalType.indexOf("1") != -1 ? this.dataForm.newDepartment_id : '',
                    newDuty_id: that.dataForm.approvalType.indexOf("2") != -1 ? this.dataForm.newDuty_id : '',
                    newDutyLevel_id: that.dataForm.approvalType.indexOf("2") != -1 ? this.dataForm.newDutyLevel_id : '',
                    reason_id: this.dataForm.reason_id,
                    remark: this.dataForm.remark,
                }
                console.log(params,"params===")
                this.disable = true
                // return
                addEmployTransferApproval(params).then(res => {
                    that.disable = false
                    responseUtil.dealResponse(that, res, () => {
                        responseUtil.alertMsg(that, res.data.msg)
                        that.dataForm = that.$options.data().dataForm
                        that.$router.go(-1)
                    })
                })   
            },
            
    
            //初始化页面数据
            initData() {
                let that = this
                that.initDicData()
                // 商圈列表
                let initData = {}
                initData.user_id = globaluserId()
                queryBusinessCircle(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data.cityList)
                        let arr = response.data.data.cityList
                        if(arr){
                            // 保持级联动层数一致
                            // arr.forEach(element => {
                            //     if(!element.children){
                            //         element.disabled = true
                            //         element.children = [{
                            //             title:'',
                            //         }]
                            //     }
                            // });
                            // 保持级联动层数一致
                            for(let i=0;i<arr.length;i++){
                                if(!arr[i].children){
                                   let emptyArr = arr.splice(i,1)
                                    arr = arr.concat(emptyArr)
                                    that.$set(arr[arr.length-1],'disabled',true)
                                    that.$set(arr[arr.length-1],'children',[{title:''}])
                                }
                            }
                        }
                        that.$set(that,'businessCircleArr',arr)
                    })
                })
                if(that.id){
                    that.title = '修改潜客'
                    initData.id = that.id
                    queryPotentialUser(initData).then( response => {
                        responseUtil.dealResponse(that, response, () => {
                            console.log(response)
                            that.formData = response.data.data
                            that.requireList = response.data.data.requireList
                            that.callBack()
                        })
                    })
                }

            },

            //初始化字典数据
            initDicData() {
                const that = this
                let initData = {}
                initData.dbName = ['rentTimeLong', 'potentialUserSource', 'requireType', 'keyNote', 'personnelTransfer']
                initData.fdName = ['STAFFGENDERMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let lists = response.data.data
                        for (var key in lists) {
                            var values = lists[key];
                            for (var i = 0, item; item = values[i++];) {
                                item.value = item.id || item.dictionaryValue;
                                item.label = item.dictionaryTitle;
                            }
                        }
                        that.resourceBelogArr = response.data.data.personnelTransfer
                        that.staffgendermap = lists.STAFFGENDERMAP
                        that.rentTimeLong = lists.rentTimeLong
                        that.potentialUserSource = lists.potentialUserSource
                        that.requireTypeArr = lists.requireType
                        that.keyNote = lists.keyNote
                        console.log(that.rentTimeLong)
                    })
                })

            },
            
            selectUserSource(value) {
                this.formData.potentialUserSourceName = value.label;
                this.formData.potentialUserSource_id = value.id
                this.isUserSourceShow = false;
            },
            selectResourceBelog(item) {
                this.dataForm.reason_id = item.id
                this.dataForm.reason_name = item.dictionaryTitle
                this.isResourceBelogShow = false;
            },
            selectRentTime(value) {
                if(this.curIndex !== "" && this.curIndex >=0){
                    this.$set(this.requireList[this.curIndex],'rentTimeLabel',value.label)
                    this.$set(this.requireList[this.curIndex],'rentTimeLong',value.value)
                }
                this.isRentTimeShow = false;
            },
            selectRequireType(value) {
                if(this.curIndex !== "" &&this.curIndex >=0){
                    this.$set(this.requireList[this.curIndex],'requireType_id',value.id)
                    this.$set(this.requireList[this.curIndex],'requireTypeName',value.label)
                }
                this.isRequireTypeShow = false;
            },
            selectKeyNoteType(item){
                this.formData.keyNote_id = item.value
                this.formData.keyNoteName = item.label
                this.isKeyNoteShow = false;
            },

            expectedDateConfirm(value){
                var date = new Date(value)
                this.dataForm.operDate = date
                this.isExpectedDateShow = false
            },

            checkCanSave(){
                let that = this
                if(!that.dataForm.staff_id || !that.dataForm.reason_id || that.dataForm.approvalType.length == 0 || ( that.dataForm.approvalType.indexOf('1') != -1 && (that.dataForm.oldDepartment_id == that.dataForm.newDepartment_id || that.dataForm.newDepartment_id == '' )) || ( that.dataForm.approvalType.indexOf('2') != -1 && ((that.dataForm.oldDuty_id == that.dataForm.newDuty_id && that.dataForm.newDutyLevel_id == that.dataForm.oldDutyLevel) || that.dataForm.newDuty_id == '' || that.dataForm.newDutyLevel_id == ''))) {
                    return false
                } else {

                    return true
                }

            },
            callBack(){
                let that = this
                that.resourceBelog = that.setSelectObj(that.resourceBelogArr,that.formData.resourceBelog)
                that.requireList.forEach(item=>{
                    if(item.rentTimeLong){
                        item.rentTimeLabel = that.setSelectObj(that.rentTimeLong,item.rentTimeLong,"value").label
                    }
                    if(item.entryTime){
                        item.entryTime = new Date(item.entryTime)
                    }
                })
            },
            setSelectObj(arr, value, keyName) {
                let obj = {}
                keyName = keyName || 'value'
                arr.forEach((item) => {
                    if (item[keyName] == value) {
                        obj = item
                    }
                })
                return obj
            },
            
        },
    }
</script>

<style scoped lang="less">

    .content {
        margin-top: 52px;
    }

    .part {
        margin: 15px;
        font-size: 14px;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-weight: 900;
        margin-bottom: 5px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        margin-bottom: 10px;
        /*border: 1px solid red;*/
    }
    .contentFill{
        height: 70px;
    }
    .part-button {
        width: 345px;
        margin: 10px 15px;
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 900;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-hyperlinks {
        float: right;
        color: #3891fa;
        font-size: 12px;
        text-decoration: underline;
        margin-top: 10px;
    }

    .part-inputpart div:last-child {
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-header {
        font-weight: 900;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        background-color: white;
        padding-top: 6px;
        /*padding-bottom: 8px;*/
        /*align-items: flex-end;*/
    }

    .part-inputpart-textarea textarea {
        height: 60px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-row input {
        border: 0;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-dropDownList {
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .content-divide-position {
        padding-top: 4px;
    }

    .part-inputpart-dropDownList-option {
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected {
        color: #ff5d3b;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .partInputpartRowGraytext {
        color: #d8d8d8;
        width: 100%;
        height: 25px;
        line-height: 25px;

    }

    .partInputpartRowNormaltext {
        color: black;
        width: 100%;
    }

    .part-inputpart-row-redtext {
        width: 100%;
        color: #ff5d3b;
    }

    /* .part-inputpart-row-housetype input {
        width: 18px;
        margin-left: 10px;
    } */

    .part-inputpart-row-right {
        float: right;
        margin-right: 20px;
        color: rgba(199, 199, 199, 1)
    }

    .part-inputpart-row-right-rightArrow {
        width: 8px;
    }

    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-noneslot {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #fff;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .part-inputpart-row-button {
        height: 60px;
    }

    .part-row-button {
        margin: 0;
        height: 35px;
        width: 100px;
        border-radius: 8px;
        line-height: 35px;
        font-size: 12px;
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-inputpart-row-short-graytext {
        color: #d8d8d8;
    }

    .radio_group {
        padding-left: 130px;
    }

    .notImport {
        padding-left: 25px;
    }

    .radio_span {
        font-size: 15px;
        color: rgba(153, 153, 153, 1);
        padding: 10px;
        padding-right: 0;

    }

    .isChecked {
        color: tomato !important;
    }

    .contentBody {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .priceInput {
        width: 82px;
        height: 31px;
        box-sizing: border-box;
        padding: 5px;
        border-radius: 5px;
        background: rgba(241, 241, 241, 1);
        color: rgba(34, 34, 34, 1);
        text-align: center;
    }
    .bottomBody{
        border-top: 1px solid #ddd;
        background: #f8f8f8;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 50;
        height:65px
    }
    //删除需求
    .deleteDemandBtnDiv{
        background-color: white;
        /*margin-bottom: 10px;*/
        height: 30px;
        line-height: 35px;
    }
    .deleteDemandBtn,.addDemandBtn{
        width: 25%;
        /*height: 35px;*/
        margin-left: 70%;
        border: none;
        color: white;
        font-size: 13px;
        border-radius: 8px;
        color: red;
        text-decoration:underline;
        /*background-image: linear-gradient(to right,#ffc274, #ff5d3b);*/
    }
</style>
